import { useState } from "react";
import { useFormik } from "formik";

import * as yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Recaptcha from "react-recaptcha";
import React, { useRef } from "react";

/*--------------------
* Contact Section
----------------------*/

const failureAlert = () => {
  toast.warning("Message failed to send.", {
    position: "bottom-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
  });
};

const successAlert = () => {
  toast.success("Message has been sent successfully!", {
    position: "bottom-left",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
  });
};
export default function Contact() {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email")
      .required("Must enter a valid email address."),
    name: yup.string().required("Name is required."),
    subject: yup.string().required("Subject is required."),
    message: yup.string().required("Message is required."),
    // recaptcha: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
      recaptcha: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      formSchema.validate(values, { abortEarly: false }).then(() => {
        fetch("https://mai-garcia-portfolio-fda18f19f759.herokuapp.com/send", {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("Server response:", response);
            const status = response.status; // Extract the status value for debugging
            console.log("Response status:", status);
            if (response.status === "success") {
              successAlert();
              formik.resetForm();
            } else if (response.status === "fail") {
              console.log("Message sending failed");
              failureAlert();
            }
          })
          .catch((errors) => {
            console.log("Validation errors:", errors);
            const errorMessages = errors.inner.map((error) => error.message);
            toast.error(errorMessages.join("\n"), {
              position: "bottom-center",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          });
      });
    },
  });

  return (
    <>
      <section
        data-scroll-data="4"
        id="contactus"
        className="section contactus-section bg-[#A2D2FF]"
        style={{
          backgroundImage: "url(img/effect/bg-effect-3.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6 ">
              <div className="p-[25px] md:p-[35px] bg-white rounded-lg">
                <h6 className="text-[32px] font-[600] text-black mb-[5px]">
                  Get in touch
                </h6>
                <p className="text-[18px] mb-[30px]">
                  Leave a message and I'll get back to you ASAP!
                </p>
                <form
                  onSubmit={formik.handleSubmit}
                  id="contact-form"
                  method="POST"
                >
                  <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12 md:col-span-6">
                      <div className="form-group">
                        <label className="form-label">Your name</label>
                        <input
                          name="name"
                          id="name"
                          placeholder="Name *"
                          className="form-control"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                        />
                        <p style={{ color: "red" }}> {formik.errors.name}</p>
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="form-group">
                        <label className="form-label">Your Email</label>
                        <input
                          name="email"
                          id="email"
                          placeholder="Email *"
                          className="form-control"
                          type="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">Subject</label>
                        <input
                          name="subject"
                          id="subject"
                          placeholder="Subject *"
                          className="form-control"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.subject}
                        />
                        <p style={{ color: "red" }}> {formik.errors.subject}</p>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">Your message</label>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Your message *"
                          rows="4"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik.values.message}
                        ></textarea>
                        <p style={{ color: "red" }}> {formik.errors.message}</p>
                      </div>
                    </div>
                    {/* <div className="col-span-12">
                      <Recaptcha
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        render="explicit"
                        verifyCallback={(response) => {
                          setFieldValue("recaptcha", response);
                        }}
                        onloadCallback={() => {
                          console.log("done loading!");
                        }}
                      />
                      {errors.recaptcha && touched.recaptcha && (
                        <p>{errors.recaptcha}</p>
                      )}
                    </div>*/}
                    <div className="col-span-12">
                      <div className="send">
                        <button
                          className="px-btn px-btn-theme2"
                          type="submit"
                          value="Send"
                        >
                          {" "}
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 flex">
              <div className="lg:max-w-[530px] w-full lg:ml-auto pt-[50px] lg:pt-0">
                <div className="pb-8">
                  <img
                    className="contact-img"
                    src="img/contact.png"
                    title=""
                    alt=""
                  />
                </div>
                <ul className="contact-infos">
                  {/* <li>
                    <div className="icon bg-1">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="col">
                      <h5>Email</h5>
                      <p>
                        <a href="mailto:garcia.ton94@gmail.com">
                          garcia.ton94@gmail.com
                        </a>
                      </p>
                    </div>
                  </li> */}
                  <li>
                    <div className="icon bg-2">
                      <a
                        href="https://www.linkedin.com/in/maitgarcia/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                    <div className="col">
                      <div className="icon bg-1">
                        <a
                          href="https://github.com/phungmaiton"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-github"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <div className="icon bg-3">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="col">
                      <h5>Visit My Studio</h5>
                      <p>
                        Warnwe Park Streetperrine, <br />
                        FL 33157 New York City
                      </p>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
}
