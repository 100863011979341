import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-scroll";

/*--------------------
* Brand
----------------------*/
function Brand(props) {
  return (
    <>
      <div className="p-3 text-center d-flex align-items-center justify-content-center w-100 ">
        <img src={props.brand_icon} title="Brand" alt="Brand" width="70%" />
      </div>
    </>
  );
}

/*--------------------
* Brand Slider
----------------------*/
export default function BrandSlider() {
  const Brand_list = [
    { id: 1, brand_icon: "img/ecc.png" },
    { id: 2, brand_icon: "img/RJE-Telecom.png" },
    { id: 3, brand_icon: "img/brand-3.svg" },
    { id: 4, brand_icon: "img/brand-4.svg" },
    { id: 5, brand_icon: "img/brand-5.svg" },
    { id: 6, brand_icon: "img/brand-6.svg" },
    { id: 7, brand_icon: "img/brand-7.svg" },
  ];

  return (
    <>
      <div className="bg-[#343a40] py-8">
        <div className="container">
          <div className="grid grid-cols-12 gap-5 items-center">
            <div className="col-span-12 lg:col-span-8 md:col-span-7 text-center md:text-left">
              <h4 className="text-[25px] md:text-[30px] lg:text-[35px] m-0 text-white font-[600]">
                Currently Accepting New Clients
              </h4>
            </div>
            <div className="col-span-12 lg:col-span-4 md:col-span-5 text-center text-md-end">
              <Link className="px-btn px-btn-theme2" to="contactus">
                Contact Now
              </Link>
            </div>
          </div>
          {/* <Swiper
            spaceBetween={20}
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
          >
            {Brand_list.map((val, i) => {
              return (
                <SwiperSlide key={i}>
                  <Brand brand_icon={val.brand_icon} />
                </SwiperSlide>
              );
            })}
          </Swiper> */}
        </div>
      </div>
    </>
  );
}
