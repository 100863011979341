import { useState } from "react";
import { Link } from "react-scroll";

/*--------------------
* Project Section
----------------------*/

function Project(props) {
  return (
    <>
      <div className="px-modal mfp-hide">
        <div className="single-project-box">
          <div className="grid grid-cols-12 gx-3">
            <div className="col-span-12 md:col-span-7 mb-10 md:mb-0">
              <div className="grid grid-cols-1 gap-2">
                {props.projectDetails.images.map((img, i) => {
                  return (
                    <div key={`image_${i}`} className="col-span-1">
                      <img className="border" src={img} title="" alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-span-12 md:col-span-5 md:pl-10 lg:pl-14">
              <h4 className="font-[600] text-[25px] text-black mb-4 pb-4 border-b">
                {props.projectDetails.title}
              </h4>
              <p className="mb-0">{props.projectDetails.description}</p>
              <p>{props.projectDetails.subDescription}</p>
              <ul className="m-0 p-0 pt-3 list-none">
                {props.projectDetails.details.map((detail, i) => {
                  return (
                    <li key={`detail_${i}`} className="flex py-2">
                      <span className="w-[100px] font-[600] text-black">
                        {detail.title}:
                      </span>
                      <a href={detail.description} target="_blank">
                        {detail.description}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <button className="px-close" onClick={props.closePopup}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

/*--------------------
* Work Section
----------------------*/

export default function Work() {
  const [showModal, setShowModal] = useState(0);
  const closePopup = () => {
    setShowModal(0);
  };

  const projectDetails = [
    {
      title: "Nomadic - Empowering Nomads, One City at a Time",
      description:
        "Nomadic is your ultimate travel companion, seamlessly blending cutting-edge technology with the spirit of adventure. With a React frontend and Flask SQLAlchemy backend, Nomadic offers a user-friendly experience like no other. Our Restful API ensures smooth navigation, while user authentication powered by bcrypt keeps your data secure. The application is designed to empower users in exploring and contrasting living expenses across the globe. ",
      images: ["img/nomadic.jpg"],
      details: [
        {
          title: "Type",
          description: "Full Stack App",
        },
        {
          title: "Tech Stack",
          description: "React.js, Flask, SQL Alchemy, TailwindCSS, Bootstrap",
        },
        {
          title: "Live URL",
          description: "https://phungmaiton.github.io/nomadic/",
        },
      ],
    },
    {
      title: "PetPals - Local Pet Meetup",
      description:
        "PetPals is a fully functional and mobile-optimized app that allows pet owners to connect with others in their area and arrange pet meetups. The app features user authentication for added security and an intuitive React front-end with a powerful Flask and SQL Alchemy backend. Users can sign up, add their pets, host or join meetups, and update their information easily. The standout feature is the ability to search for meetups within a specific distance radius.",
      images: ["img/petpals.jpg"],
      details: [
        {
          title: "Type",
          description: "Full Stack App",
        },
        {
          title: "Tech Stack",
          description: "React.js, Flask, SQL Alchemy, TailwindCSS, Bootstrap",
        },
        {
          title: "Live URL",
          description: "https://phungmaiton.github.io/petpals/",
        },
      ],
    },
    {
      title: "첫친구 - Korean Language Learning Resource",
      description:
        "Implemented a React app specifically designed for Korean language learners, facilitating quick access to a curated collection of resources highly regarded by fellow learners. Leveraging the collective knowledge of users, the app enables resource submission to contribute to the growing bank of valuable materials. To enhance user experience, the homepage prominently displays popular resources that dynamically update based on the number of likes garnered by each item.",

      images: ["img/first-friend.jpg"],
      details: [
        {
          title: "Type",
          description: "React Web App",
        },
        {
          title: "Tech Stack",
          description: "React.js, Tailwind",
        },
        {
          title: "Demo URL",
          description: "https://phungmaiton.github.io/first-friend/",
        },
      ],
    },
    {
      title: "Website Design for Ervin Cable Construction",
      description:
        "I spearheaded a comprehensive project for Ervin Cable Construction, developing a dynamic and visually compelling website using WordPress. From concept to completion, I meticulously crafted a tailored online presence that perfectly captured the company's brand identity and showcased its services. By integrating intuitive navigation, engaging multimedia elements, and seamless functionality, the new website offers visitors an immersive experience while effectively promoting Ervin Cable Construction's capabilities and expertise.    ",
      images: ["img/ervin-cable.jpg"],
      details: [
        {
          title: "Type",
          description: "Website",
        },
        {
          title: "Platform",
          description: "WordPress",
        },
        {
          title: "Live URL",
          description: "https://ervincable.com",
        },
      ],
    },
  ];
  return (
    <>
      <section
        data-scroll-data="3"
        id="portfolio"
        className="section bg-[#fdf0f5]"
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>Latest Projects</span>
              </h3>
            </div>
          </div>
          <div className="lightbox-gallery portfolio-box">
            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                <div className="portfolio-img">
                  <img src="img/nomadic.jpg" title="" alt="" />
                  <a
                    href="https://phungmaiton.github.io/nomadic/"
                    className="gallery-link gallery-link-icon"
                    target="_blank"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Full Stack App</span>
                  </h6>
                  <h4>Nomadic – Empowering Nomads</h4>
                  <p>
                    Nomadic, a dynamic and comprehensive full-stack application
                    designed to empower users in exploring and contrasting
                    living expenses across the globe.
                  </p>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => setShowModal(1)}
                    >
                      View Project{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                <div className="portfolio-img">
                  <img src="img/petpals.jpg" title="" alt="" />
                  <a
                    href="https://phungmaiton.github.io/petpals/"
                    className="gallery-link gallery-link-icon"
                    target="_blank"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Full Stack App</span>
                  </h6>
                  <h4>PetPals - Local Pet Meetups</h4>
                  <p>
                    Created a full stack app for pet owners to discover local
                    meetups for your beloved pets because we believe our furry
                    friends deserve to have pals too.
                  </p>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => setShowModal(2)}
                    >
                      View Project{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                <div className="portfolio-img">
                  <img src="img/first-friend.jpg" title="" alt="" />
                  <a
                    href="https://phungmaiton.github.io/first-friend/"
                    className="gallery-link gallery-link-icon"
                    target="_blank"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>React Web App</span>
                  </h6>
                  <h4>첫친구 - Korean Language Learning Resource</h4>
                  <p>
                    Developed a mobile-responsive React app for Korean language
                    learners, enabling seamless resource discovery and
                    contribution.
                  </p>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => setShowModal(3)}
                    >
                      View Project{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                <div className="portfolio-img">
                  <img src="img/ervincable.com.jpg" title="" alt="" />
                  <a
                    href="https://ervincable.com"
                    className="gallery-link gallery-link-icon"
                    target="_blank"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>WordPress</span>
                  </h6>
                  <h4>Website Design for Ervin Cable Construction</h4>
                  <p>
                    Created a bespoke website for Ervin Cable Construction,
                    delivering a fresh and customized online presence.
                  </p>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => setShowModal(4)}
                    >
                      View Project{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showModal ? (
        <>
          <Project
            closePopup={closePopup}
            projectDetails={projectDetails[showModal - 1]}
          ></Project>
        </>
      ) : null}
    </>
  );
}
