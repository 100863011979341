/*--------------------
* Service Section
----------------------*/
function Service(props) {
  return (
    <>
      <div className="lg:col-span-6">
        <div className={props.id.main + " feature-box-01"}>
          <div className="icon">
            <i className={props.id.icon}></i>
          </div>
          <div className="feature-content">
            <h5>{props.title}</h5>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

/*--------------------
* Service List Section
----------------------*/
export default function ServiceList() {
  const service_list = [
    {
      id: {
        main: "bg-1 rounded-lg",
        icon: "fas fa-columns",
        iconContainer: "bg-3 rounded-lg",
      },
      title: "Web Design",
      description:
        "I specialize in crafting visually captivating websites that captivate audiences and drive tangible results. By seamlessly merging cutting-edge design trends with exceptional functionality, I create tailor-made online experiences that leave a lasting impression.",
    },
    {
      id: { main: "bg-1 rounded-lg", icon: "fas fa-laptop" },
      title: "Front-End Development",
      description:
        "I bring web apps to life with stunning visuals and seamless user experiences. Leveraging my expertise in HTML, CSS, JavaScript, and modern frameworks like React, I create dynamic and interactive web interfaces that captivate users, delivering an exceptional experience across devices.",
    },
    {
      id: { main: "bg-1 rounded-lg", icon: "fas fa-code" },
      title: "Full-Stack Development",
      description:
        "I provide end-to-end solutions that encompass both front-end and back-end development, bringing your vision to life by building robust and scalable web apps. From designing intuitive UI to developing powerful server-side logic, I ensure seamless functionality and UX from start to finish.",
    },
    {
      id: { main: "bg-1 rounded-lg", icon: "fas fa-mobile-alt" },
      title: "Mobile App Development",
      description:
        "I excel in crafting high-performance, cross-platform mobile applications that seamlessly run on iOS and Android devices. Harnessing the power of React Native's robust framework, I deliver visually stunning and feature-rich mobile apps that captivate users and provide an exceptional user experience.",
    },
  ];
  return (
    <>
      <section
        data-scroll-data="1"
        id="services"
        className="section service`s-section bg-gray"
        style={{
          backgroundImage: "url(img/effect/bg-effect-1.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>My Services</span>
              </h3>
            </div>
          </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-5 grid-cols-1 items-center">
            {service_list.map((val, i) => {
              return (
                <Service
                  key={i}
                  id={val.id}
                  title={val.title}
                  description={val.description}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
