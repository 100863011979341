/*--------------------
* Footer Section
----------------------*/
function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="footer bg-[#343a40] border-t border-white border-opacity-10 py-4">
      <div className="container">
        <div className="grid grid-cols-12">
          {/* <div className="col-span-12 md:col-span-6 flex pb-3 md:pb-0">
            <div className="flex justify-center md:justify-start w-full">
              <a
                className="text-white text-opacity-90 hover:text-[#ffc8dd] mr-5 text-[16px]"
                href="https://www.linkedin.com/in/maitgarcia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                className="text-white text-opacity-90 hover:text-[#ffc8dd] mr-5 text-[16px]"
                href="https://github.com/phungmaiton"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-github"></i>
              </a>
            </div>
          </div> */}
          <div className="col-span-12 md:col-span-12 text-right md:text-right">
            <p className="m-0 text-white text-opacity-75">
              &copy; {year} Mai Garcia All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
